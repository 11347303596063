
import { Layout, Toast, TextArea, Tag, Modal, Descriptions, ButtonGroup, Nav, Typography, Tabs, Input, Table, TabPane, Button, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';
import { IconBell, IconRefresh2, IconHelpCircle, IconImport, IconBytedanceLogo, IconHome, IconMore, IconHistogram, IconLive, IconSetting } from '@douyinfe/semi-icons';
import { ethers } from 'ethers';
import moment from 'moment';
import React, { Component, useState } from 'react';
import { parseInt } from 'lodash';




const App = (props) => {

    const { Header, Footer, Sider, Content } = Layout;
    const { Column } = Table;
    const { Text } = Typography;
    
    const pageSize = 5;
    const [address, setAddress] = useState("");
    const [monitorTextArea, setMonitorTextArea] = useState("");
    const [monitorAddr, setMonitorAddr] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [itemsData, setItemsData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0)

    const [trace, setTrace] = useState({});
    const [ethData, setEthData] = useState([]);
    const [walletStatistics, setWalletStatistics] = useState([]);

    const columns = [
        // {
        //     title: '钱包',
        //     dataIndex: 'zz',
        //     ellipsis: true,
        //     width: 400,
        //     render: value => {
        //         return address;
        //     }
        // },
        {
            title: '转出地址',
            dataIndex: 'from_address',
            ellipsis: true,
            width: 200,
        },
        {
            title: '转入地址',
            dataIndex: 'to_address',
            ellipsis: true,
            width: 200,
        },
        {
            title: '交易金额',
            width: 150,
            dataIndex: 'quant',
            render: (text, record, index) => {
                return (
                    <div>
                        <Avatar size="small" src='https://static.coinall.ltd/cdn/oksupport/asset/currency/icon/usdt20230419113051.png' style={{ marginRight: 4 }}>
                        </Avatar>
                        {ethers.formatUnits(text, 6)}
                    </div>
                );
            },
        },
        {
            title: '交易时间',
            dataIndex: 'block_ts',
            width: 200,
            render: value => {
                const time = value;
                const date = new Date(time);
                const year = date.getFullYear();
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const day = ("0" + date.getDate()).slice(-2);
                const hours = ("0" + date.getHours()).slice(-2);
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                return formattedDate;
            },
        },
        {
            title: '交易hash',
            dataIndex: 'transaction_id',
            ellipsis: true,
            width: 150,
            render: value => {
                return (
                    <Text link={{ target: '_blank', href: `https://tronscan.org/#/transaction/${value}` }}>查看hash</Text>
                );
            }
        },
    ];

    const columnsTrace = [
        {
            title: '钱包',
            dataIndex: 'toAddress',
            ellipsis: true,
            width: 200,
        },
        {
            title: '转账次数',
            dataIndex: 'count',
            ellipsis: true,
            width: 200,
            sorter: (a, b) => (a.count - b.count > 0 ? 1 : -1),
        },
        {
            title: '转账金额',
            width: 150,
            dataIndex: 'amount',
            sorter: (a, b) => (a.amount - b.amount > 0 ? 1 : -1),
        },
        {
            title: '',
            dataIndex: 'items',
            width: 150,
            render: value => {
                const downProps = {};
                downProps.onClick = () => showTransItemDialog(value);
                return (
                    <>
                    <Button type="primary" {...downProps}>查看明细</Button> 
                    </>
                );
            }
        },
    ];

    /**
     * 获取溯源数据
     */
     const fetchTraceData = async ()=>{

        if(address === ''){
            Toast.warning("请输入地址");
            return;
        }
        setLoading(true);
        let trcData = await getTransByAddress(-179,  address);
        trcData = trcData.filter(item => item.contract_address === 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t' )
        setTrace(traceDataHandle(trcData))
        setLoading(false);
    }

    function convertTimestamp(timestamp) {
        return new Date(parseInt(timestamp) * 1000);
    }

    const traceDataHandle = (rawData)=>{
        let outCount = 0;     
        let outAmount = 0; 
        let totalCount = 0;   
        let totalAmount = 0;  
        let groupByTo = [];
        const {start_date, end_date} = getDateRange(180);
        rawData.forEach(tx => {
            const ts = tx.block_ts;
            if (ts >= start_date && ts <= end_date) {
                totalCount++;
                totalAmount += Number(ethers.formatUnits(tx.quant, 6), 2);
                
                if (tx.from_address === address) {
                  outCount++;
                  outAmount += Number(ethers.formatUnits(tx.quant, 6), 2);

                  let item = groupByTo.find(i => i.toAddress === tx.to_address);
                    if (item) {
                        item.count++;
                        item.amount += Number(ethers.formatUnits(tx.quant, 6), 2);
                        item.items.push(tx);
                    } else {
                        groupByTo.push({
                            toAddress: tx.to_address,
                            count: 1,
                            amount: Number(ethers.formatUnits(tx.quant, 6), 2),
                            items: [tx] 
                        });
                    }
                }
            }
          });
          const trace = {
            address: address,
            outCount: outCount,
            outAmount: outAmount,
            children: groupByTo
        }
        return trace;
    }

    const fetchAnalysis = async () => {
        if(localStorage.getItem("trc_wallet") === null){
            Toast.warning("请先导入钱包");
            return;
        }
        setLoading(true);
        const walletList = JSON.parse(localStorage.getItem("trc_wallet"));
        let tempList = [];
        for(const item of walletList){
            let trcData = await getTransByAddress(-6, item);
            // || item.contract_address === 'TRcwpHu65TxvBkkqx7thxwiLqxc6V8jdTc' || item.contract_address === 'TTmQYPPZ3N3AfSFx4NKm4or2zwDn8dvKRE'
            trcData = trcData.filter(item => item.contract_address === 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t' )
            const { start_date: start_7d, end_date: end_7d } = getDateRange(7);
            const { received: received_7d, sent: sent_7d } = count(trcData, item.address, start_7d, end_7d);
            tempList.push({ label: item.label, address: item.address, received: Number(received_7d, 2), sent: Number(sent_7d, 2) })
        }
        setWalletStatistics(tempList);
        setLoading(false);
    }

    const getTransByAddress = async (day, query_address, start = 0)=>{

        const headers = new Headers({
            'Accept': 'application/json, text/plain, */*',
            'Accept-Encoding': 'gzip, deflate, br',
            'Accept-Language': 'zh-CN,zh;q=0.9',
            'Connection': 'keep-alive',
            'Host': 'apilist.tronscanapi.com',
            'Origin': 'https://tronscan.org',
            'Referer': 'https://tronscan.org/',
            'sec-ch-ua': 'Chromium";v="106", "Google Chrome";v="106", "Not;A=Brand";v="99',
            'sec-ch-ua-mobile': '?0',
            'sec-ch-ua-platform': 'macOS',
            'Sec-Fetch-Dest': 'empty',
            'Sec-Fetch-Mode': 'cors',
            'Sec-Fetch-Site': 'cross-site',
            'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/106.0.0.0 Safari/537.36'
          });
       
        const limit = 50;
        const { startTime, endTime} = getCurrentTime(day);
        let total = 0;
        const res = await fetch(
            `https://apilist.tronscanapi.com/api/token_trc20/transfers?limit=${limit}&start=${start}&sort=-timestamp&count=true&filterTokenValue=0&start_timestamp=${startTime}&end_timestamp=${endTime}&relatedAddress=${query_address}`
        , { headers: headers});
        const data = await res.json();
        total = data.rangeTotal;
        let num = (Math.ceil(total / limit)) + 1;

        
        let transList =  await recursiveCall(day, headers, num, query_address, -1, []);
        // data.token_transfers.map(item =>{
        //     transList.push({ quant: item.quant, transaction_id: item.transaction_id, from_address: item.from_address, to_address:item.to_address,block_ts: item.block_ts  });
        // })
        return transList;
    }

    
    async function apiRequest(headers, address, startTime, endTime, size) {
        let numSize = size * 50
        const res = await fetch(
            `https://apilist.tronscanapi.com/api/token_trc20/transfers?limit=50&start=${numSize}&sort=-timestamp&count=true&filterTokenValue=0&start_timestamp=${startTime}&end_timestamp=${endTime}&relatedAddress=${address}`
        ,{headers: headers});
        const data = await res.json();
        return data.token_transfers;
    }

    async function recursiveCall(day, headers, count, query_address, size, results = []) {
        // 退出条件
        if (count <= 0) {
          return results;
        }
        const { startTime, endTime} = getCurrentTime(day);

        size = size + 1

        // 调用API
        const result = await apiRequest(headers, query_address, startTime, endTime, size);

        result.map(item =>{
            results.push({ tokenDecimal: item.tokenInfo.tokenDecimal, contract_address: item.contract_address, quant: item.quant, transaction_id: item.transaction_id, from_address: item.from_address, to_address:item.to_address,block_ts: item.block_ts  });
        })
        await new Promise(resolve => setTimeout(resolve, 2000));
        return recursiveCall(day, headers, count - 1, query_address, size, results);
      }

    const getDateRange = (n) => {
        const end_date = new Date();
        const start_date = new Date(end_date.getTime() - (n - 1) * 24 * 60 * 60 * 1000);
        return { start_date, end_date };
    }

    function count(data, address, start_date, end_date) {
        let received = 0;
        let sent = 0;
        data.forEach(item => {
            const ts = item.block_ts;
            if (ts >= start_date && ts <= end_date) {
                if (item.to_address === address) {
                    // console.log("rece:" + ethers.formatUnits(item.quant, 6));
                    received += Number(ethers.formatUnits(item.quant, item.tokenDecimal));
                } else if (item.from_address === address) {
                    // console.log("sent:" + ethers.formatUnits(item.quant, item.tokenDecimal) + ", hash：" + item.transaction_id);
                    sent += Number(ethers.formatUnits(item.quant, item.tokenDecimal));
                }
            }
        });
        return { received, sent };
    }

    const handleSearchClick = async () => {
        if(address === ''){
            Toast.warning("请输入地址");
            return;
        }
        setLoading(true);
        const { startTime, endTime} = getCurrentTime();
        const res = await fetch(
            `https://apilist.tronscanapi.com/api/token_trc20/transfers?limit=10&start=0&sort=-timestamp&count=true&filterTokenValue=0&start_timestamp=${startTime}&end_timestamp=${endTime}&relatedAddress=${address}`
        );
        const data = await res.json();
        setTotalNumber(data.rangeTotal);
        setDataSource(data.token_transfers);
        setLoading(false);
    };

    const handlePageChange = async page => {
        await fetchData(page);
    };

    const fetchData = async (currentPage = 1) => {
        const limit = 10;
        const start = currentPage === 1 ? 0 : currentPage * limit;
        const { startTime, endTime} = getCurrentTime();
        setLoading(true);
        setPage(currentPage);
        const res = await fetch(
            `https://apilist.tronscanapi.com/api/token_trc20/transfers?limit=${limit}&start=${start}&sort=-timestamp&count=true&filterTokenValue=0&start_timestamp=${startTime}&end_timestamp=${endTime}&relatedAddress=${address}
            `
        );
        const data = await res.json();
        setTotalNumber(data.rangeTotal);
        setDataSource(data.token_transfers);
        setLoading(false);
    };

    const getCurrentTime = (day)=>{
        console.log("day:" + day)
        let startTime = moment().startOf('day').add(day, 'day').add(8, 'hour').valueOf();
        let endTime = moment().startOf('day').add(1, 'day').add(8, 'hour').valueOf();
        return {startTime, endTime}
    }

    const [visible, setVisible] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const [showTransItem, setShowTransItem] = useState(false);
    const showDialog = () => {
        setVisible(true);
    };

    const showWallteDialog = () => {
        if(localStorage.getItem("trc_wallet") === null){
            Toast.warning("请先导入钱包");
            return;
        }
        setShowWallet(true);
    };

    const showTransItemDialog = (items) => {
        setShowTransItem(true);
        setItemsData(items);
    };

    const handleOk = () => {
        setVisible(false);
        const lines = monitorTextArea.split("\n");
        let walletList = [];
        for (let i = 0; i < lines.length; i++) {
            const wallet = lines[i].split(",");
            walletList.push({ label: wallet[0], address: wallet[1] })
        }
        localStorage.setItem("trc_wallet", JSON.stringify(walletList))
        Toast.success('已导入' + walletList.length + "个钱包")
    };

    const handleCancel = () => {
        setVisible(false);
        setShowWallet(false);
        setShowTransItem(false);
        console.log('Cancel button clicked');
    };

    const handleAfterClose = () => {
        console.log('After Close callback executed');
    };

    return (
        <>

            <Modal
                title="导入钱包"
                visible={visible}
                maskClosable={false}
                width={500}
                onOk={handleOk}
                afterClose={handleAfterClose} //>=1.16.0
                onCancel={handleCancel}
                closeOnEsc={true}
            >
                一行一钱包,格式：钱包标签,钱包地址
                <br />
                <br />
                例如：大户1,TUXdpGSqGKR1PU3QuvLUNz6UksEue5ZvHw
                <br />
                <br />
                <TextArea onChange={setMonitorTextArea} value={monitorTextArea} />
            </Modal>
            <Modal
                title="查看已导入钱包"
                visible={showWallet}
                maskClosable={false}
                width={500}
                onOk={handleOk}
                afterClose={handleAfterClose} //>=1.16.0
                onCancel={handleCancel}
                closeOnEsc={true}
                footer={null}
            >
                <Table dataSource={JSON.parse(localStorage.getItem("trc_wallet"))} pagination={false}>
                    <Column title="标签" dataIndex="label" key="label" />
                    <Column title="钱包" dataIndex="address" key="address" />
                </Table>
            </Modal>

            <Modal
                title="交易明细"
                visible={showTransItem}
                width={1000}
                onCancel={handleCancel}
                closeOnEsc={true}
                footer={null}
            >
                <Table loading={loading} columns={columns} dataSource={itemsData} />
            </Modal>

        <Tabs type="line">
            <TabPane tab="查询" itemKey="1">
                <Input placeholder={'TUXd....pvHw'} onChange={setAddress} value={address} style={{ width: 720, marginTop: 12, marginBottom: 12 }} />
                <Button style={{ marginLeft: 20 }} onClick={handleSearchClick} type="primary" htmlType="submit">查询</Button>
                <Table pagination={{ currentPage, pageSize: 10, total: totalNumber, onPageChange: handlePageChange, }} loading={loading} columns={columns} dataSource={dataSource} />
            </TabPane>

            <TabPane tab="统计" itemKey="2">
                <ButtonGroup theme="borderless">
                    <Button onClick={showDialog} icon={<IconImport />} type='primary' style={{ marginTop: 6, marginRight: 8 }}>导入钱包</Button>
                    <Button onClick={showWallteDialog} icon={<IconHistogram />} type='secondary' style={{ marginTop: 6, marginRight: 8 }}>查看已导入钱包</Button>
                    <Button onClick={fetchAnalysis} icon={<IconRefresh2 />} type='danger' style={{ marginTop: 6, marginRight: 8 }}>刷新</Button>
                </ButtonGroup>
                <h3>查询分析</h3>
                <div style={{ border: '1px solid var(--semi-color-border)' }}>
                    <Table loading={loading} dataSource={walletStatistics} pagination={false}>
                        <Column title="标签" dataIndex="label" key="label" />
                        <Column title="钱包" dataIndex="address" key="address" />
                        <Column title="近7天转入(usdt)" dataIndex="received" key="received" />
                        <Column title="近7天转出(usdt)" dataIndex="sent" key="sent" />
                    </Table>
                </div>
            </TabPane>

            <TabPane tab="资金溯源" itemKey="3">
                <Input  placeholder = { 'TUXd....pvHw' } onChange={setAddress} value={address} style={{ width: 720, marginTop: 12, marginBottom: 12 }} />
                <Button style={{ marginLeft: 20 }} onClick={fetchTraceData} type="primary" htmlType="submit">查询</Button>
                <Table loading={loading} columns={ columnsTrace } dataSource={ trace.children } pagination={true} />
            </TabPane>
        </Tabs>
        </>
    );
}

export default App;