
import { Layout, Card, Toast, TextArea, Tag, Modal, Descriptions, ButtonGroup, Nav, Typography, Tabs, Input, Table, TabPane, Button, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';
import { IconBell, IconRefresh2, IconHelpCircle, IconImport, IconBytedanceLogo, IconHome, IconMore, IconHistogram, IconLive, IconSetting } from '@douyinfe/semi-icons';
import { ethers } from 'ethers';

import DemoFlowAnalysisGraph from '../component/fundTracing'

import React, { Component, useState } from 'react';




const App = (props) => {

    const { Header, Footer, Sider, Content } = Layout;
    const { Column } = Table;
    const { Text } = Typography;

    const [address, setAddress] = useState("");
    const [monitorTextArea, setMonitorTextArea] = useState("");
    const [monitorAddr, setMonitorAddr] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [itemsData, setItemsData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);

    const [trace, setTrace] = useState({});

    const [ethData, setEthData] = useState([]);
    const [walletStatistics, setWalletStatistics] = useState([]);

    const columns = [
        // {
        //     title: '钱包',
        //     dataIndex: 'zz',
        //     ellipsis: true,
        //     width: 400,
        //     render: value => {
        //         return address;
        //     }
        // },
        {
            title: '转出地址',
            dataIndex: 'from',
            ellipsis: true,
            width: 200,
        },
        {
            title: '转入地址',
            dataIndex: 'to',
            ellipsis: true,
            width: 200,
        },
        {
            title: '交易金额',
            width: 150,
            dataIndex: 'value',
            render: (text, record, index) => {
                return (
                    <div>
                        <Avatar size="small" src='https://static.coinall.ltd/cdn/oksupport/asset/currency/icon/usdt20230419113051.png' style={{ marginRight: 4 }}>
                        </Avatar>
                        {ethers.formatUnits(text, 6)}
                    </div>
                );
            },
        },
        {
            title: '交易时间',
            dataIndex: 'timeStamp',
            width: 200,
            render: value => {
                const time = value * 1000;
                const date = new Date(time);
                const year = date.getFullYear();
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const day = ("0" + date.getDate()).slice(-2);
                const hours = ("0" + date.getHours()).slice(-2);
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                return formattedDate;
            },
        },
        {
            title: '交易hash',
            dataIndex: 'hash',
            ellipsis: true,
            width: 150,
            render: value => {
                return (
                    <Text link={{ target: '_blank', href: `https://etherscan.io/tx/${value}` }}>查看hash</Text>
                );
            }
        },
    ];

    const columnsTrace = [
        {
            title: '钱包',
            dataIndex: 'toAddress',
            ellipsis: true,
            width: 200,
        },
        {
            title: '转账次数',
            dataIndex: 'count',
            ellipsis: true,
            width: 200,
            sorter: (a, b) => (a.count - b.count > 0 ? 1 : -1),
        },
        {
            title: '转账金额',
            width: 150,
            dataIndex: 'amount',
            sorter: (a, b) => (a.amount - b.amount > 0 ? 1 : -1),
        },
        {
            title: '',
            dataIndex: 'items',
            width: 150,
            render: value => {
                const downProps = {};
                downProps.onClick = () => showTransItemDialog(value);
                return (
                    <>
                    <Button type="primary" {...downProps}>查看明细</Button> 
                    </>
                );
            }
        },
    ];



    /**
     * 获取溯源数据
     */
    const fetchTraceData = async ()=>{

        if(address === ''){
            Toast.warning("请输入地址");
            return;
        }

        setLoading(true);

        const res = await fetch(
            `https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=0xdac17f958d2ee523a2206206994597c13d831ec7&address=${address}&page=1&offset=10000&startblock=0&endblock=99999999&sort=desc&apikey=2QWBFM754QXHBDTM9FRCJR8ADJ2HHMS74Z`
        );
        const data = await res.json();
        let ethData = data.result;
        setTrace(traceDataHandle(ethData))
        setLoading(false);
    }

    const traceDataHandle = (rawData)=>{
        let totalCount = 0;   
        let totalAmount = 0;  
        let outCount = 0;     
        let outAmount = 0; 

        let groupByTo = [];

        const {start_date, end_date} = getDateRange(180);

        rawData.forEach(tx => {
            const ts = convertTimestamp(tx.timeStamp);
            if (ts >= start_date && ts <= end_date) {
                totalCount++;
                totalAmount += Number(ethers.formatUnits(tx.value, 6), 2);
                
                if (tx.from.toLowerCase() === address.toLowerCase()) {
                  outCount++;
                  outAmount += Number(ethers.formatUnits(tx.value, 6), 2);

                  let item = groupByTo.find(i => i.toAddress.toLowerCase() === tx.to.toLowerCase());
                    if (item) {
                        item.count++;
                        item.amount += Number(ethers.formatUnits(tx.value, 6), 2);
                        item.items.push(tx);
                    } else {
                        groupByTo.push({
                            toAddress: tx.to,
                            count: 1,
                            amount: Number(ethers.formatUnits(tx.value, 6), 2),
                            items: [tx] 
                        });
                    }
                }
                
            }
          });
          const trace = {
            address: address,
            outCount: outCount,
            outAmount: outAmount,
            children: groupByTo
        }
        return trace;
    }



    const fetchAnalysis = async () => {
        if(localStorage.getItem("wallet") === null){
            Toast.warning("请先导入钱包");
            return;
        }
        setLoading(true);
        const walletList = JSON.parse(localStorage.getItem("wallet"));
        let tempList = [];
        for(const item of walletList){
            const res = await fetch(
                `https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=0xdac17f958d2ee523a2206206994597c13d831ec7&address=${item.address}&page=1&offset=10000&startblock=0&endblock=99999999&sort=desc&apikey=2QWBFM754QXHBDTM9FRCJR8ADJ2HHMS74Z`
            );
            const data = await res.json();
            let ethData = data.result;
            const { start_date: start_7d, end_date: end_7d } = getDateRange(7);
            const { received: received_7d, sent: sent_7d } = count(ethData, item.address, start_7d, end_7d);
            tempList.push({ label: item.label, address: item.address, received: received_7d, sent: sent_7d })
        }
        setWalletStatistics(tempList);
        setLoading(false);
    }

    const getDateRange = (n) => {
        const end_date = new Date();
        const start_date = new Date(end_date.getTime() - (n - 1) * 24 * 60 * 60 * 1000);
        return { start_date, end_date };
    }

    function count(data, address, start_date, end_date) {
        let received = 0;
        let sent = 0;
        console.log(data.length)
        data.forEach(item => {
            const ts = convertTimestamp(item.timeStamp);
            if (ts >= start_date && ts <= end_date) {
                if (item.to.toLowerCase() === address.toLowerCase()) {
                    // console.log("rece:" + ethers.formatUnits(item.value, 6));
                    received += Number(ethers.formatUnits(item.value, 6));
                } else if (item.from.toLowerCase() === address.toLowerCase()) {
                    // console.log("sent:" + ethers.formatUnits(item.value, 6));
                    sent += Number(ethers.formatUnits(item.value, 6));
                }
            }
        });
        return { received, sent };
    }

    function convertTimestamp(timestamp) {
        return new Date(parseInt(timestamp) * 1000);
    }

    const handleSearchClick = async () => {

        if(address === ''){
            Toast.warning("请输入地址");
            return;
        }

        setLoading(true);
        const res = await fetch(
            `https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=0xdac17f958d2ee523a2206206994597c13d831ec7&address=${address}&page=1&offset=10000&startblock=0&endblock=99999999&sort=desc&apikey=2QWBFM754QXHBDTM9FRCJR8ADJ2HHMS74Z`
        );
        const data = await res.json();
        setDataSource(data.result)
        setLoading(false);
    };
    const [visible, setVisible] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const [showTransItem, setShowTransItem] = useState(false);

    
    const showDialog = () => {
        setVisible(true);
    };

    const showWallteDialog = () => {
        if(localStorage.getItem("wallet") === null){
            Toast.warning("请先导入钱包");
            return;
        }
        setShowWallet(true);
    };

    const showTransItemDialog = (items) => {
        setShowTransItem(true);
        setItemsData(items);
    };

    const handleOk = () => {
        setVisible(false);
        const lines = monitorTextArea.split("\n");
        let walletList = [];
        for (let i = 0; i < lines.length; i++) {
            const wallet = lines[i].split(",");
            walletList.push({ label: wallet[0], address: wallet[1] })
        }
        localStorage.setItem("wallet", JSON.stringify(walletList))
        Toast.success('已导入' + walletList.length + "个钱包")
    };

    const handleCancel = () => {
        setVisible(false);
        setShowWallet(false);
        setShowTransItem(false);
        console.log('Cancel button clicked');
    };

    const handleAfterClose = () => {
        console.log('After Close callback executed');
    };

    return (
        <>

            <Modal
                title="导入钱包"
                visible={visible}
                maskClosable={false}
                width={500}
                onOk={handleOk}
                afterClose={handleAfterClose} //>=1.16.0
                onCancel={handleCancel}
                closeOnEsc={true}
            >
                一行一钱包,格式：钱包标签,钱包地址
                <br />
                <br />
                例如：大户1,0x0999....9999
                <br />
                <br />
                <TextArea onChange={setMonitorTextArea} value={monitorTextArea} />
            </Modal>
            <Modal
                title="查看已导入钱包"
                visible={showWallet}
                maskClosable={false}
                width={500}
                onOk={handleOk}
                afterClose={handleAfterClose} //>=1.16.0
                onCancel={handleCancel}
                closeOnEsc={true}
                footer={null}
            >
                <Table dataSource={JSON.parse(localStorage.getItem("wallet"))} pagination={false}>
                    <Column title="标签" dataIndex="label" key="label" />
                    <Column title="钱包" dataIndex="address" key="address" />
                </Table>
            </Modal>

            <Modal
                title="交易明细"
                visible={showTransItem}
                width={1000}
                onCancel={handleCancel}
                closeOnEsc={true}
                footer={null}
            >
                <Table loading={loading} columns={columns} dataSource={itemsData} />
            </Modal>

        <Tabs type="line">
            <TabPane tab="查询" itemKey="1">
                <Input  placeholder = { '0x0999....9999' } onChange={setAddress} value={address} style={{ width: 720, marginTop: 12, marginBottom: 12 }} />
                <Button style={{ marginLeft: 20 }} onClick={handleSearchClick} type="primary" htmlType="submit">查询</Button>
                <Table loading={loading} columns={columns} dataSource={dataSource} />
            </TabPane>
            <TabPane tab="统计" itemKey="2">
                <ButtonGroup theme="borderless">
                    <Button onClick={showDialog} icon={<IconImport />} type='primary' style={{ marginTop: 6, marginRight: 8 }}>导入钱包</Button>
                    <Button onClick={showWallteDialog} icon={<IconHistogram />} type='secondary' style={{ marginTop: 6, marginRight: 8 }}>查看已导入钱包</Button>
                    <Button onClick={fetchAnalysis} icon={<IconRefresh2 />} type='danger' style={{ marginTop: 6, marginRight: 8 }}>刷新</Button>
                </ButtonGroup>
                <h3>查询分析</h3>
                <div style={{ border: '1px solid var(--semi-color-border)' }}>
                    <Table loading={loading} dataSource={walletStatistics} pagination={false}>
                        <Column title="标签" dataIndex="label" key="label" />
                        <Column title="钱包" dataIndex="address" key="address" />
                        <Column title="近7天转入(usdt)" dataIndex="received" key="received" />
                        <Column title="近7天转出(usdt)" dataIndex="sent" key="sent" />
                    </Table>
                </div>
            </TabPane>

            <TabPane tab="资金溯源" itemKey="3">
                <Input  placeholder = { '0x0999....9999' } onChange={setAddress} value={address} style={{ width: 720, marginTop: 12, marginBottom: 12 }} />
                <Button style={{ marginLeft: 20 }} onClick={fetchTraceData} type="primary" htmlType="submit">查询</Button>
                <Table loading={loading} columns={ columnsTrace } dataSource={ trace.children } pagination={true} />
            </TabPane>
        </Tabs>
        </>
    );
}

export default App;